import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import DangerBoxURL from 'components/1440p_Series_CGI_List/DangerBoxURL';
import IntroCards from 'components/1440p_Series_CGI_List/System_Menu/systemCGI';
import NavButtons from 'components/1440p_Series_CGI_List/NavButtons';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='1440p Series CGI List :: System Menu' dateChanged='2021-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='1440p Series CGI List :: System Menu' image='/images/Search/AU_SearchThumb_CGICommands_1440p.png' twitter='/images/Search/AU_SearchThumb_CGICommands_1440p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/1440p_Serie_CGI_Befehle/System_Menu/' locationFR='/fr/1440p_Series_CGI_List/System_Menu/' crumbLabel="System" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBoxURL mdxType="DangerBoxURL" />
    <EuiSpacer mdxType="EuiSpacer" />
    <IntroCards mdxType="IntroCards" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      